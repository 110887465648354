import { template as template_facf98332ae04891b5032da56a3483d9 } from "@ember/template-compiler";
const FKLabel = template_facf98332ae04891b5032da56a3483d9(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
