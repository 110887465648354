import { template as template_be1f5f818b9f4ad6944e0dce0c387c24 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_be1f5f818b9f4ad6944e0dce0c387c24(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
